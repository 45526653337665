import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FiLink2 } from "react-icons/fi";

import { Box, Flex, Grid, Image, Text } from "atoms";
import { GetLink } from "molecules/GetLink";

export const ProfileCard = ({
  name,
  designation,
  qualification,
  specification,
  email,
  phone,
  image,
  profileUrl,
}) => {
  return (
    <Grid
      gridTemplateColumns={{ xs: "12rem 1fr", xm: "22rem 1fr" }}      
      width="100%"
      bg="grey.50"      
      transition="all 300ms ease-in-out"
      borderRadius="0.6rem"
      overflow="hidden"
      _hover={{
        transition: "border 300ms ease-in-out",
        borderBottom: "5px solid",      
        borderColor: "primary.500",        
      }}
    >
      <Box
        minWidth={{ xs: "12rem", xm: "22rem" }}        
        height="100%"
        borderRadius="0.4rem 0 0 0.4rem"
        overflow="hidden"
      >
        <Image
          src={image}
          alt={name}
          width="100%"
          height="100%"
          objectFit="cover"          
        />
      </Box>
      <Flex
        flexGrow="1"
        px={{xs: "1.5rem", xm: "2rem"}}
        flexDirection="column"
        justifyContent="space-between"
        minHeight={{ xs: "14rem", xm: "20rem" }}
      >
        <Box>
          <Text
            as="h2"
            fontSize={{ xs: "1.4rem", md: "1.6rem" }}
            fontWeight="500"          
            _hover={{ color: "primary.500" }}
            mb="1rem"
            className="heading-border"
          >
            {name}
          </Text>
          {designation && <Text          
            fontSize={{ xs: "1.2rem", md: "1.4rem" }}
            fontWeight="400"
            color="secondary.500"
          >
            {designation}
          </Text>}
          {qualification && <Text          
            fontSize={{ xs: "1.2rem", md: "1.4rem" }}
            fontWeight="400"
            color="grey.300"
          >
            {qualification}
          </Text>}
          {specification && <Text          
            fontSize={{ xs: "1.2rem", md: "1.4rem" }}
            fontWeight="400"
            color="grey.300"
          >
            {specification}
          </Text>}
        </Box>
        <Flex
          justifyContent="space-between"          
          my="1rem"           
        > 
          <Flex
            flexDirection="column"
            mr="1rem"
            style={{ gap: "0.8rem" }}            
          >
            {email?.map((em) => (
              <Icon
                key={em?.id}
                Icon={<AiOutlineMail/>}
                label={em?.value}
                url={`mailto:${em?.value}`}
              />
            ))} 
            {phone?.map((ph) => (
              <Icon
                key={ph?.id}
                Icon={<AiOutlinePhone/>}
                label={ph?.value}
                url={`tel:${ph?.value}`}
              />
            ))}                        
          </Flex>
          {profileUrl && <Box
            cursor="pointer"
            _hover={{ color: "primary.500" }}
            mt="auto"
            fontSize={{xs: "2rem", xm: "2.5rem"}}
          >
            <GetLink
              url={profileUrl}              
            >
              <FiLink2/>
            </GetLink>
          </Box>}
        </Flex>
      </Flex>
    </Grid>
  )
}

const Icon = ({
  Icon,
  label,
  url
}) => (
  <GetLink
    url={url}
  >
    <Flex
      width="fit-content"
      alignItems="center" 
      color="accent.500"     
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        mr="1rem"
        fontSize="1.6rem"
      >
        {Icon}
      </Flex>
      <Text                
        fontSize={{ xs: "1.2rem", md: "1.4rem" }}
        fontWeight="500"        
        cursor="pointer"
        className="break-word"                
      >
        {label}
      </Text>
    </Flex>
  </GetLink>
)