import { Box, Grid, Text } from "atoms";
import { ResourceCard } from "molecules";

export const HomeResources = ({
  resources
}) => {
  return (
    <Box
      maxWidth="120rem"
      mx="auto"
      mb="4rem"
      px={{xs: "2rem", md: "4rem", lg: "0"}}
    >
      <Text 
        pb="2rem"        
        mb="2rem"
        fontSize="2.4rem"
        className="heading-border" 
        fontWeight="600"       
      >
        Resources at your fingertips
      </Text>
      <Grid
        gridTemplateColumns={{xs: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr"}}
        gridGap={{xs: "2rem", md: "3rem"}}
        mb="2rem"
        justifyItems="center"        
      >
        {resources?.map((resource) => (
          <ResourceCard
            key={resource?.id}
            title={resource?.title}
            links={resource?.resources}
          />
        ))}        
      </Grid>
    </Box>
  )
}