import React, { useState } from "react";
import { Lightbox } from "react-modal-image";

import { ImageWrapper } from "./Image.styles";

export const Image = React.memo(({ src, alt, objectFit, borderRadius, preview=false, ...rest }) => {

  const [ imgPreview, setImgPreview ] = useState(false);

  return(
    <>
      <ImageWrapper 
        {...rest}
        onClick={() => preview && setImgPreview(true)}
        cursor={preview ? "pointer" : "unset"}
      >         
        <img src={src} alt={alt} width="100%" height="100%" style={{objectFit: objectFit || "unset", borderRadius: borderRadius || "unset"}} />
      </ImageWrapper>
      {imgPreview && <Lightbox
        medium={src}
        large={src}
        alt={alt}
        onClose={() => setImgPreview(false)}
      />}
    </>
  )
});
