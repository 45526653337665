import { Box, Flex, Grid, Text } from "atoms";
import { GetLink, NewsCard } from "molecules";

export const HomeNewsEvents = ({
  news,  
}) => {
  return (
    <Box
      maxWidth="120rem"
      mx="auto"
      mb="4rem"
      px={{xs: "2rem", md: "4rem", lg: "0"}}
    >
      <Text 
        pb="2rem"        
        mb="2rem"
        fontSize="2.4rem"
        className="heading-border"
        fontWeight="600"               
      >
        News and Events
      </Text>
      <Flex
        flexDirection={{xs: "column", md: "row"}}        
      >
        {news?.length > 0 && <Box flex="1" mb="2rem">
          <Grid            
            gridTemplateColumns={{xs: "1fr", md: "1fr", lg: "1fr 1fr"}}
            gridGap={{xs: "2rem", xm: "3rem", lg: "4rem"}}                 
            pb="4rem"
            borderBottom="1px solid"
            borderColor="primary.500"
          >
            {news?.map((news) => (
              <NewsCard
                key={news?.id}
                image={news?.image?.url}
                title={news?.title}
                timestamp={news?.date || news?.createdAt}
                tag={news?.tag}
                type={news?.type}
                id={news?.id}              
                slug={news?.slug}                                        
              />
            ))}          
          </Grid>
          <GetLink url="/news-events">
            <Text              
              fontSize="1.4rem"
              fontWeight="500"
              _hover={{color: "primary.500"}}
              textTransform="uppercase"              
            >
              View All
            </Text>
          </GetLink>
        </Box>}        
      </Flex>
    </Box>
  )
}