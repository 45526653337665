import { Box, Flex, HeroSection, Text } from "atoms";
import { SyllabusCard, ResultCard } from "molecules";
import { formatDate, generatePublicURL } from "utils/utilities";

export const EventDetailsPage = ({
  event
}) => {

  let timestamp = formatDate(event?.date);

  return (
    <Box>          
      <HeroSection
        image="/images/home-contact.jpg"
        breadcrumb={[
          {label: "Home", url: "/"},
          {label: "Events", url: "/events"},
          {label: "Event Details", url: `/event/${event?.slug}`}
        ]}
      />
      <Flex
        maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
        m="5rem auto"   
        py={{xm: "3rem"}}   
        as="article"  
        flexDirection={{xs: "column", md: "row"}}    
      >        
        <Flex          
          bg="primary.500"
          color="white"
          p="1.4rem 1rem"
          minWidth="10rem"
          alignItems="center"
          flexDirection="column"
          mr={{md: "1.5rem"}}         
        >
          <Text fontSize="1.2rem">
            {timestamp?.split(" ")[1]}
          </Text>
          <Text fontSize="4rem">
            {timestamp?.split(" ")[2].substring(0, timestamp?.split(" ")[2].length-1)}
          </Text>
          <Text fontSize="1,2rem">
            {timestamp?.split(" ")[3]}
          </Text>        
        </Flex>
        <Box 
          mt={{xs: "1rem", md: "unset"}}
          textAlign={{xs: "center", md: "left"}}
        >
          <Text
            fontSize={{xs: "2rem", md: "3rem"}}
            fontWeight="500"
            mb="1.2rem"
            color="primary.500"            
          >
            {event?.title}
          </Text>          
          {event?.description?.split("<br/>").map((para, index) => (
            <Text 
              key={index} 
              mb="1rem"
              fontSize={{xs: "1.4rem", md: "1.6rem"}}
              color="grey.300"                
              className="text-justify"           
            >
              {para}
            </Text>              
          ))}                   
        </Box>
      </Flex>
      <Flex
        maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
        m="1rem auto"
        style={{gap: "1rem"}}
        flexDirection={{xs: "column", md: "row"}}
        pb="3rem"
      >
        {event?.docs?.length > 0 && <SrcDiv
          title="Related Documents"
        >          
          {event.docs?.map((src) => (                   
            <SyllabusCard
              key={src?.id}
              title={src?.title}
              href={generatePublicURL(src?.data?.data?.attributes?.url)}
            />                             
          ))}
        </SrcDiv>}
        {event?.links?.length > 0 && <SrcDiv
          title="Related Links"
        >
          {event.links?.map((src) => (
            <ResultCard
              key={src?.id}
              title={src?.title}
              href={src?.url}                        
            />
          ))}
        </SrcDiv>}
      </Flex>
    </Box>
  )
}

const SrcDiv = ({
  title,
  children
}) => (
  <Box
    boxShadow="0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.1)"
    p="2rem"
    flex="1"
    borderRadius="0.5rem"
    width="100%"
  >
    <Text
      fontSize={{xs: "1.6rem", md: "2rem"}}
      fontWeight="500"
      mb="1.2rem"
      color="primary.500"
    >
      {title}
    </Text>
    {children}   
  </Box>
)