import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Flex } from "atoms";
import { GalleryCard, PageSearchLayout } from "molecules";
import { getAlbums } from "redux/actions";
import { formatDate, generatePublicURL } from "utils/utilities";

export const AlbumsPage = () => {

  const dispatch = useDispatch();
  const { albums } = useSelector(state => state.data);    

  const [ search, setSearch ] = useState("");
  const [ page, setPage ] = useState(1);  

  useEffect(() => {    
    dispatch(getAlbums(
      page,
      12,
      search    
    ));
  }, [page, search, dispatch]);

  return (
    <>      
      <PageSearchLayout
        heroData={{
          image: "/images/home-contact.jpg",
          breadcrumb: [
            {label: "Home", url: "/"},
            {label: "Albums", url: "/albums"}
          ]
        }}
        search={search}
        setSearch={setSearch}
        totalCount={albums?.meta?.pagination?.total}
        resultPerPage={albums?.meta?.pagination?.pageSize}
        page={page}
        setPage={setPage}
        notFound="No albums found"
      >      
        <Flex
          style={{gap: "3rem"}}
          flexWrap="wrap"
          justifyContent="center"
        >
          {albums?.data?.map((album) => (
            <GalleryCard
              key={album?.id}
              slug={album?.attributes?.slug}
              title={album?.attributes?.title?.length > 50 ? album?.attributes?.title?.slice(0, 50)+"..." : album?.attributes?.title}
              image={generatePublicURL(album?.attributes?.thumbnail?.data?.attributes?.url)}
              items={album?.attributes?.images?.data?.length}
              timestamp={formatDate(album?.attributes?.date)}
            />
          ))}          
        </Flex>           
      </PageSearchLayout>
    </>
  )
}