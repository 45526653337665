import { useSelector } from "react-redux";
import { SocialIcon } from "react-social-icons";

import { Box, Button, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";

export const Footer = () => {

  const { footer } = useSelector(state => state.app);

  return (
    <Box
      as="footer"
      bg="dark.100"
      px={{ xs: "3rem", md: "6rem", xm: "9rem", lg: "17rem" }}      
    >   
      {/* FOOTER TOP    */}
      <Flex
        py={{ xs: "3rem", lg: "4rem" }}        
        flexDirection={{ xs: "column", xm: "row" }}
        alignItems={{ xs: "center", xm: "start" }}
        justifyContent={{xm: "space-between"}}
        borderBottom="1px solid #808080"
      >
        <Flex
          flexDirection={{ xs: "column", xm: "row" }}          
          alignItems={{ xs: "center", xm: "start" }}
        >
          <Box
            minWidth={{ xs: "8rem", md: "12rem" }}
            height="auto"
            maxWidth={{ xs: "8rem", md: "12rem" }}            
            mr={{ xs: "0", md: "3rem" }}            
          >
            <Image
              src="/images/white-logo.png"
              alt={footer?.logo?.alt}
              width="100%"
              height="100%"
              objectFit="contain"
              className="white-image"
            />
          </Box>
          <Box 
            mt={{xs: "1rem", xm: "unset"}}            
          >
            <Text               
              color="grey.100"
              fontSize={{ xs: "1.4rem", md: "1.6rem" }}
              textAlign={{ xs: "center", xm: "start" }}
              maxWidth={{xm: "56rem"}}
            >
              Vasudev College of Law gives you access to{" "}
              <Text
                as="span"
                color="white"
                fontWeight="600"
                fontSize={{ xs: "1.4rem", md: "1.6rem" }}
              >
                exceptional legal education and training.
              </Text> 
              {" "}We are committed to providing you with the{" "}
              <Text
                as="span"
                color="white"
                fontWeight="600"
                fontSize={{ xs: "1.4rem", md: "1.6rem" }}
              >
                skills and knowledge
              </Text>
              {" "}you need to succeed in the legal profession.
            </Text>
          </Box>
        </Flex>   
        {footer?.button && <GetLink
          url={footer?.button?.url}               
        >
          <Button
            width="fit-content"
            borderRadius="0.4rem"
            p="1rem 2rem"
            variant="secondary"
            mt={{ xs: "2.5rem", xm: "unset" }}
          >
            {footer?.button?.title}
          </Button> 
        </GetLink>}            
      </Flex>
      {/* FOOTER BOTTOM */}
      <Flex 
        py={{ xs: "3rem", lg: "4rem" }}
        flexDirection={{ xs: "column-reverse", xm: "column" }}
      >
        {/* LOGO & SOCIAL LINKS */}
        <Flex
          flexDirection={{ xs: "column", xm: "row" }}
          alignItems="start"
          justifyContent={{xm: "space-between"}}
        >          
          <Flex
            justifyContent={{ xs: "center", xm: "start" }}
            width={{ xs: "100%", xm: "unset" }}
            mt={{ xs: "2rem", xm: "unset" }}
          >
            {footer?.socials?.map((link) => (                              
              <SocialIcon 
                key={link?.id}
                url={link?.url} 
                fgColor="#F4F4F4"
                bgColor="transparent"
                style={{
                  height: "4rem",
                  width: "4rem",                    
                  margin: "0 0.5rem",                                        
                }}
                className="social-icon"
              />                   
            ))}
          </Flex>
        </Flex>
        {/* FOOTER LINKS */}
        <Flex 
          color="white"
          flexDirection={{ xs: "row", md: "column" }}
          mt={{md: "3rem"}}
        >
          <Flex
            justifyContent="start"
            width="100%"
            flexWrap={{md: "wrap"}}
            flexDirection={{xs: "column", md: "row"}}
          >
            {footer?.topNav?.map(link => (
              <Box
                key={link?.id}
                mr="2rem"
                mb="1rem"
                color="white"                
                fontSize={{ xs: "1.2rem", md: "1.4rem" }}
                _hover={{ color: "secondary.100" }}
              >
                <GetLink
                  className={(navData) => navData.isActive ? "active-secondary" : "" }
                  url={link?.url}
                  type="nav"
                >
                  {link?.title}
                </GetLink>
              </Box>
            ))}
          </Flex>

          <Flex
            justifyContent="start"
            width="100%"
            flexWrap={{md: "wrap"}}
            flexDirection={{xs: "column", md: "row"}}
          >
            {footer?.bottomNav?.map(link => (
              <Box
                key={link?.id}
                mr="2rem"
                mb="1rem"
                color="grey.300"
                _hover={{ color: "secondary.100" }}
                fontSize={{ xs: "1.2rem", md: "1.4rem" }}
              >
                <GetLink
                  className={(navData) => navData.isActive ? "active-secondary" : "" }
                  url={link?.url}
                  type="nav"
                >
                  {link?.title}
                </GetLink>
              </Box>
            ))}
          </Flex>
        </Flex>
      </Flex>
      {/* COPYRIGHT TEXT */}
      <Text
        color="grey.300"
        fontSize={{ xs: "1.2rem", md: "1.4rem" }}
        textAlign="center"      
        width="100%"  
      >
        © 2023 Vasudev College of Law. All rights reserved.
      </Text>
      {/* CREATOR CREDIT */}
      <Text
        color="grey.300"
        fontSize={{ xs: "1.2rem", md: "1.4rem" }}
        textAlign="center"
        pb="2rem"
        pt="0.5rem"
        width="100%"
      >
        Powered by{" "}
        <Text
          as="a"
          href="https://pgkonnect.com"
          target="_blank"
          rel="noreferrer"
          color="secondary.100"
          fontSize={{ xs: "1.2rem", md: "1.4rem" }}
        >
          Prathma Global Konnect Pvt. Ltd.
        </Text>
      </Text>
    </Box>
  )
}