import { useEffect } from "react";

import SEO from "seo/SEO";
import { scrollToTop } from "utils/utilities";
import { CoursesPage } from "templates";

const Courses = () => {
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Academic Programs | Vasudev College Of Law, Haldwani"
        description="Academic Programs of Vasudev College Of Law, Haldwani"
        keywords="Academic Programs Vasudev College Of Law, Academic Programs Vasudev College Of Law, Haldwani, SAcademic Programs Vasudev College Of Law"        
      />  
      <CoursesPage/>       
    </>
  )
}

export default Courses;