import React from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Box, Button, Input, Text } from "atoms";
import { sendContactMail } from "redux/actions";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid format")
    .required("Email Required")
    .max(100, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),
  name: Yup.string()
    .required("Name Required")
    .max(100, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),  
  message: Yup.string().max(2000, "Max 2000 characters allowed"),
});

export const ContactForm = () => {

  const dispatch = useDispatch();  
  const { loading } = useSelector(state => state.app);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    const form = {
      name: values.name,
      email: values.email,      
      message: values.message,      
    }           
    dispatch(sendContactMail(form));       
    setSubmitting(false);    
    resetForm();
  }

  return (
    <>      
      <Box 
        maxWidth="100%"         
        px={{xs: "3rem", md: "6rem", xm: "4rem"}}
        py={{xs: "5rem", md: "6rem", xm: "4rem"}}
      >
        <Text
          fontSize="1.8rem"
          fontWeight="600"
          mb="1rem"
        >
          Contact Form
        </Text>
        <Text
          fontSize="1.6rem"
          fontWeight="400"
          color="grey.100"
          mb="3rem"
        >
          Having a query? Feel free to Contact Us
        </Text>
        <Formik
          initialValues={{
            name: "",            
            email: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ touched, errors, values }) => {
            return (
              <Form>
                <Input                  
                  name="name"
                  type="text"
                  label="Name*"
                  id="name"                                    
                  touched={touched.name}
                  errors={errors.name}  
                  formik={true} 
                  value={values.name}  
                  mb="1.2rem"                           
                />  
                <Input                  
                  name="email"
                  type="email"
                  label="Email*"
                  id="email"                                    
                  touched={touched.email}
                  errors={errors.email}
                  formik={true}
                  value={values.email}                           
                  mb="1.2rem"                           
                />                          
                <Input                  
                  name="message"
                  as="textarea"
                  label="Message*"
                  id="message"                                    
                  rows="5"
                  touched={touched.message}
                  errors={errors.message}  
                  formik={true}                                                                      
                  value={values.message}
                  mb="1.6rem"            
                />              
                <Button
                  variant="success"
                  width="14rem"                  
                  px="2rem"
                  py="1rem"
                  type="submit"
                  ml="auto"
                  mt="3rem"
                  fontSize="1.6rem"
                  borderRadius="0.5rem"
                  loading={loading}
                >                  
                  Submit                 
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}