import { useEffect } from "react";

import SEO from "seo/SEO";
import { HomePage } from "templates";
import { scrollToTop } from "utils/utilities";

export const Home = () => {
  
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>      
      <SEO
        title="Best Emerging Law College of Uttarakhand - Vasudev College Of Law"
        description="Discover the best emerging law college of Uttarakhand - Vasudev College Of Law. We offer top-quality legal education and a vibrant campus life. Apply now to start your journey!"
        keywords="Vasudev College Of Law, Haldwani"
      /> 
      <HomePage/>
    </>
  );
};

export default Home;
