import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { CourseCard, PageSearchLayout } from "molecules";
import { getCourses } from "redux/actions";
import { generatePublicURL } from "utils/utilities";

export const CoursesPage = () => {

  const dispatch = useDispatch();
  const { courses } = useSelector(state => state.data);  

  const [ search, setSearch ] = useState("");
  const [ page, setPage ] = useState(1);

  useEffect(() => {    
    dispatch(getCourses(
      page,
      12,
      search
    ));    
  }, [search, page, dispatch]);

  return (
    <>
      <PageSearchLayout
        heroData={{
          image: "/images/home-contact.jpg",
          breadcrumb: [
            {label: "Home", url: "/"},
            {label: "Courses Offered", url: "/academics/courses"}
          ]
        }}
        search={search}
        setSearch={setSearch}
        totalCount={courses?.meta?.pagination?.total}
        resultPerPage={courses?.meta?.pagination?.pageSize}
        page={page}
        setPage={setPage}
        notFound="No courses found"
      > 
        <Grid
          gridTemplateColumns={{xs: "1fr", md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)"}}
          gridGap="2.5rem"
          mt={{xs: "1rem", md: "2rem"}}
        >
          {courses?.data?.map((item) => (
            <CourseCard
              key={item.id}
              id={item?.id}
              slug={item?.attributes?.slug}            
              image={generatePublicURL(item?.attributes?.image?.data?.attributes?.url)}
              title={item?.attributes?.title}
              description={item?.attributes?.description}
              time={item?.attributes?.duration}
              seats={item?.attributes?.seats}
              shadow={true}
            />
          ))}
        </Grid>          
      </PageSearchLayout>
    </>
  )
}