import { useEffect } from "react";

import SEO from "seo/SEO";
import { NewsEventPage } from "templates";
import { scrollToTop } from "utils/utilities";

const NewsEvents = () => {
  useEffect(() => {
    scrollToTop(window);
  }, [])

  return (
    <>
      <SEO
        title="News & Events | Vasudev College Of Law, Haldwani"
        description="News & Events of Vasudev College Of Law, Haldwani"
        keywords="News & Events Vasudev College Of Law, Haldwani, News & Events Vasudev College Of Law, News & Events Vasudev College Of Law, Haldwani, News & Events Vasudev College Of Law"        
      />      
      <NewsEventPage/>
    </>
  )
}

export default NewsEvents;