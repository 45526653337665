import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex, Grid, Text } from "atoms";
import { ConferenceWorkshopCard, PageSearchLayout } from "molecules";
import { getConferenceWorkshops } from "redux/actions";
import { generatePublicURL } from "utils/utilities";

const filters = [
  "conference",
  "workshop",
]

export const ConferenceWorkshopsPage = () => {

  const dispatch = useDispatch();
  const { cw } = useSelector(state => state.data);    

  const [ search, setSearch ] = useState("");  
  const [ page, setPage ] = useState(1);  
  const [ type, setType ] = useState("");

  useEffect(() => {    
    dispatch(getConferenceWorkshops(
      page,
      12,
      search,
      type
    ));
  }, [page, search, type, dispatch]);

  useEffect(() => {    
    setPage(1);
  }, [type]); 

  const extractTime = (dateTime) => {    
    const date = new Date(dateTime);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours}:${minutes}`;    
  }

  const extractDate = (dateTime) => {   
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return `${day}/${month}/${year}`;
  }

  return (
    <>            
      <PageSearchLayout
        heroData={{
          image: "/images/home-contact.jpg",
          breadcrumb: [
            {label: "Home", url: "/"},
            { label: "Conferences & Workshops", url: "/conference-workshops" }
          ]
        }}
        search={search}
        setSearch={setSearch}
        totalCount={cw?.meta?.pagination?.total}
        resultPerPage={cw?.meta?.pagination?.pageSize}
        page={page}
        setPage={setPage}
        notFound="No conferences or workshops found"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"  
          style={{ gap: "1rem" }}  
          mb="4rem"      
        >
          {filters?.map((filter, index) => (
            <Box
              key={index}
              onClick={() => setType(filter)}
              color={type === filter ? "white" : "primary.500"}
              bg={type === filter ? "primary.500" : "white"}
              border="1px solid"
              borderColor="primary.500"
              borderRadius="0.4rem"
              p="0.6rem 1.5rem"
              cursor="pointer"
              transition="all 300ms ease-in-out"
              _hover={{ color: "white", bg: "primary.500" }}
            >
              <Text
                as="span"
                fontSize="1.4rem"
                fontWeight="400"
                textTransform="uppercase"
                textAlign="center"
              >
                {filter}
              </Text>
            </Box>
          ))}
          <Box             
            onClick={() => setType("")}
            bg={"primary.500"}
            color={"white"}
            border="1px solid"
            borderColor="primary.500"
            borderRadius="0.4rem"
            p="0.7rem 2rem"
            cursor="pointer"
            transition="all 300ms ease-in-out"
            _hover={{ bg: "white", color: "primary.500" }}
          >
            <Text
              as="span"
              fontSize="1.4rem"
              fontWeight="400"
              textTransform="uppercase"
              textAlign="center"
            >
              CLEAR
            </Text>
          </Box>
        </Flex>
        <Grid
          gridTemplateColumns={{xs: "1fr", xm: "repeat(2, 1fr)"}}
          gridGap="2.5rem"
        >
          {cw?.data?.map((item) => (
            <ConferenceWorkshopCard
              key={item?.id}
              title={item?.attributes?.title}
              description={item?.attributes?.description}
              date={extractDate(item?.attributes?.dateTime)}
              mode={item?.attributes?.mode}
              time={extractTime(item?.attributes?.dateTime)}
              image={generatePublicURL(item?.attributes?.image?.data?.attributes?.url)}
              url={item?.attributes?.joinUrl}
              type={item?.attributes?.type}
              venue={item?.attributes?.joinLocation}
              docs={item?.attributes?.docs?.map((doc) => ({
                title: doc?.title,
                url: generatePublicURL(doc?.data?.data?.attributes?.url)
              }))}
              links={item?.attributes?.links?.map((link) => ({
                title: link?.title,
                url: link?.url
              }))}
            />
          ))}
        </Grid>        
      </PageSearchLayout>
    </>
  )
}