import React, { useState } from "react";

import { Box, Flex, Grid, HeroSection, Image, Text } from "atoms";
import { SyllabusCard, ResultCard } from "molecules";
import { generatePublicURL } from "utils/utilities";

export const CourseDetailsPage = ({
  course
}) => {

  const tabs = [
    {label: "Overview", value: "overview"},
    {label: "Facilities", value: "facilities"},
    {label: "Career Opportunities", value: "opportunity"},
    {label: "Fee Structure", value: "fee-structure"},    
  ]

  const [activeTab, setActiveTab] = useState(tabs[0]?.value);  

  return (
    <>
      <HeroSection
        image={generatePublicURL(course?.data?.image?.url)}        
        breadcrumb={[
          {
            label: "Home",
            url: "/"
          },
          {
            label: "Courses",
            url: "/courses"
          },
          {
            label: "Course Details",
            url: "#"
          }
        ]}
      />

      {course && <Box
        bg="white"                
        p="6rem 3rem"
        as="section"
        maxWidth={{xs: "100%", lg: "120rem"}}
        mx="auto"
      >
        {course?.data?.title && <Text          
          fontSize={{xs: "2rem", md: "2.4rem"}}
          fontWeight="700"
          mb="2rem"
          color="primary.500"
        >
          {course?.data?.title}
        </Text>}
        <Grid
          gridTemplateColumns={{xs: "1fr", xm: "1.8fr 1fr"}}
          gridGap="2.4rem"          
        >
          <Box>            
            {course?.data?.image && <Box
              mb={{xs: "2rem", md: "3rem"}}
              maxHeight="48rem"
              overflow="hidden"              
            >
              <Image
                src={generatePublicURL(course?.data?.image?.url)}
                alt={course?.data?.title}
                width="100%"
                height="100%"
                objectFit="cover"
              />
            </Box>}

            <Flex
              mb={{xs: "2rem", md: "3rem"}}
              boxShadow="0 0 20px 3px rgba(0, 0, 0, 0.05)"              
              flexWrap="wrap"                    
              minWidth="100%"
            >
              {tabs?.map((tab, index) => (
                <Text
                  key={index}
                  flex="1"                  
                  minWidth="fit-content"                  
                  fontSize="1.6rem"
                  fontWeight="500"
                  p="1.6rem 2.4rem"
                  color={activeTab === tab?.value ? "white" : "#707070"}                  
                  bg={activeTab === tab?.value ? "secondary.500" : "white"}
                  cursor="pointer"
                  onClick={() => setActiveTab(tab?.value)}
                  transition="all 0.3s ease"
                  _hover={{
                    color: activeTab === tab?.value ? "white" : "secondary.500",
                  }}
                >
                  {tab?.label}
                </Text>
              ))}
            </Flex>

            <Box>
              {course?.details?.data?.filter(detail => detail?.type === activeTab)?.map((item) => {
                switch(item?.__component){
                  case "section.course-text":
                    return(
                      <PopulateTitleValue
                        title={item?.title}
                        values={item?.description?.split("<br/>")}
                      />
                    )

                  case "section.course-list":
                    return(
                      <PopulateTitleValue
                        title={item?.title}
                        values={item?.data?.map((li) => li?.value)}
                        type="list"
                      />
                    )

                  case "section.course-table":
                    return(
                      <PopulateTable
                        values={item?.data?.map((row) => row)}
                      />
                    )
  
                  default:
                    return null;
                }
              })}
            </Box>
          </Box>

          <Box>
            {course?.details?.docs && <SrcBox
              title={"Related Documents"}
            >              
              <Grid
                gridTemplateColumns="1fr"
                gridGap="1rem"
              >
                {course?.details?.docs?.map((item) => (
                  <SyllabusCard
                    key={item?.id}
                    title={item?.title}
                    href={generatePublicURL(item?.data?.url)}
                  />
                ))}
              </Grid>
            </SrcBox>}
            {course?.details?.links &&<SrcBox
              title={"Related Links"}
            >              
              <Grid
                gridTemplateColumns="1fr"
                gridGap="1rem"
              >
                {course?.details?.links?.map((item) => (
                  <ResultCard
                    key={item?.id}
                    title={item?.title}
                    href={item?.url}
                  />
                ))}
              </Grid>            
            </SrcBox>}
          </Box>
        </Grid>
        
      </Box>}
    </>
  )
}

const PopulateTitleValue = ({ title, values, type="paragraph" }) => (
  <Box
    mb={{xs: "2rem", md: "3rem"}}
  >
    {title && <Text
      fontSize={{ xs: "1.8rem", md: "2rem", lg: "2.4rem"}}
      fontWeight="600"      
      className="heading-border"
      mb="3rem"
    >
      {title}
    </Text>}    
    {values?.map((value, index) => (
      <>
        {type === "list" && <Flex 
          alignItems="center"         
          mb="1.5rem"         
        >
          <Box
            minWidth="1rem"
            minHeight="1rem"
            maxWidth="1rem"
            maxHeight="1rem"            
            bg="secondary.500"
            borderRadius="50%"
            mr="1rem"            
          />
          <Text      
            key={index}            
            fontSize="1.5rem"
            fontWeight="400"
            color="grey.300"
          >
            {value}
          </Text>
        </Flex>}
        {type === "paragraph" && <Text
          fontSize="1.5rem"
          color="grey.300"
          fontWeight="400"
          mb="2.5rem" 
          className="text-justify"
        >
          {value}
        </Text>}
      </>     
    ))}
  </Box>
)

const PopulateTable = ({ values }) => (
  <Box
    style={{overflowX: "auto"}}    
    maxWidth={{
      xs: "calc(100vw - 6rem)",
      md: "unset"
    }}
  >    
    <Box
      as="table"      
      border="1px solid #707070"
      borderCollapse="collapse"
      mb="3rem"      
    >    
      {values?.map((item, index) => (
        <Box
          as="tr"
          key={index}
        >
          {item?.map((cell, i) => (
            <Box
              as="td"
              key={i}
              p="0.6rem"
              border="1px solid #707070"
            >
              <Text
                as="p"
                fontSize="1.4rem"
                fontWeight={cell?.type === "head" ? "700" :"500"}            
                color={cell?.type === "head" ? "primary.500" :"#707070"}            
              >
                {cell?.value}
              </Text>
            </Box>          
          ))}
        </Box>
      ))}
    </Box>
  </Box>
)

const SrcBox = ({ 
  title,
  children
}) => (
  <Box
    width="100%"    
    borderRadius="0.5rem"
    p="2rem"    
    mb="2rem"  
    boxShadow="0 0 20px 3px rgba(0, 0, 0, 0.05)"        
  >
    <Text                
      fontSize="2.2rem"
      fontWeight="600"
      mb="2rem"
      color="primary.500"
    >
      {title}
    </Text>

    {children}
  </Box>
)