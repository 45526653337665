import { config } from "config";

export const isMobile = () => (typeof window !== "undefined" ? window.innerWidth <= 600 : false);
export const isTab = () => (typeof window !== "undefined" ? window.innerWidth <= 1024 : false);

export const formatDate = (date) => {
  let formattedDate;
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thusday", "Friday", "Saturday"]
  if(typeof date === "object"){    
    formattedDate = `${dayNames[date?.toDate().getDay()]}, ${monthNames[date?.toDate().getMonth()]} ${date?.toDate().getDate()}, ${date?.toDate().getFullYear()}`
  }else if(typeof date === "string"){            
    const _date = new Date(date);
    formattedDate = `${dayNames[_date.getDay()]}, ${monthNames[_date.getMonth()]} ${_date.getDate()}, ${_date.getFullYear()}`    
  }
  return formattedDate
}

export const scrollToTop = (window) =>{
  window.scrollTo({top: 0, left: 0, behavior: "smooth" })
}    

export const printINR= (cost) => {
  return new Intl.NumberFormat("en-IN", {style: "currency", currency: "INR", maximumSignificantDigits: 3}).format(cost)
}

export const generatePublicURL = (file) => {
  if(file?.includes("http") || file.includes("https")) return file;    
  if(file?.includes("/uploads/")) return config.MEDIA_URL + file  
  return file
}

export const deSlugAndCapitalize = (slug) => {
  return slug.split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
}