import { useEffect } from "react";

import SEO from "seo/SEO";
import { scrollToTop } from "utils/utilities";
import { ContactUsPage } from "templates";

function ContactUs() {
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Contact Us | Vasudev College Of Law, Haldwani"
        description="Contact Us of Vasudev College Of Law, Haldwani"
        keywords="Contact Us Vasudev College Of Law, Haldwani, Contact Us Vasudev College Of Law"        
      />  
      <ContactUsPage/>
    </>
  )
}

export default ContactUs;