import { dataConstants } from "redux/constants";

const initState = {      
  error: null,    
  loading: false, 
  message: null,
  aboutPage: null,
  news: null,
  newsDetails: null,
  events: null,
  eventDetails: null,
  albums: null,
  albumDetails: null,
  faculty: null,
  cw: null,
  courses: null,
  courseDetails: null,
  syllabus: null,
  result: null,
  examSchedule: null,
}

export const dataReducer = (state = initState, action) => {
  switch(action.type){
    case dataConstants.GET_ABOUT_PAGE_DATA_REQUEST:
    case dataConstants.GET_NEWS_REQUEST:
    case dataConstants.GET_NEWS_DETAILS_REQUEST:
    case dataConstants.GET_EVENTS_REQUEST:
    case dataConstants.GET_EVENT_DETAILS_REQUEST:
    case dataConstants.GET_ALBUMS_REQUEST:
    case dataConstants.GET_ALBUM_DETAILS_REQUEST:
    case dataConstants.GET_FACULTY_REQUEST:
    case dataConstants.GET_CONFERENCES_AND_WORKSHOPS_REQUEST:
    case dataConstants.GET_COURSES_REQUEST:
    case dataConstants.GET_COURSE_DETAILS_REQUEST:
    case dataConstants.GET_SYLLABUS_REQUEST:
    case dataConstants.GET_RESULTS_REQUEST:
    case dataConstants.GET_EXAM_SCHEDULE_REQUEST:      
      return {
        ...state,
        loading: true,
      }

    case dataConstants.GET_ABOUT_PAGE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutPage: action.payload,
      }

    case dataConstants.GET_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        news: action.payload,
      }

    case dataConstants.GET_NEWS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        newsDetails: action.payload,
      }

    case dataConstants.GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload,
      }

    case dataConstants.GET_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        eventDetails: action.payload,
      }
    
    case dataConstants.GET_ALBUMS_SUCCESS:
      return {
        ...state,
        loading: false,
        albums: action.payload,
      }
    
    case dataConstants.GET_ALBUM_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        albumDetails: action.payload,
      }
    
    case dataConstants.GET_FACULTY_SUCCESS:
      return {
        ...state,
        loading: false,
        faculty: action.payload,
      }
      
    case dataConstants.GET_CONFERENCES_AND_WORKSHOPS_SUCCESS:
      return {
        ...state,
        loading: false,
        cw: action.payload,
      }
    
    case dataConstants.GET_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload,
      }
    
    case dataConstants.GET_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        courseDetails: action.payload,
      }
    
    case dataConstants.GET_SYLLABUS_SUCCESS:
      return {
        ...state,
        loading: false,
        syllabus: action.payload,
      }
    
    case dataConstants.GET_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      }

    case dataConstants.GET_EXAM_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        examSchedule: action.payload,
      }
    
    case dataConstants.GET_ABOUT_PAGE_DATA_FAILURE:
    case dataConstants.GET_NEWS_FAILURE:
    case dataConstants.GET_NEWS_DETAILS_FAILURE:
    case dataConstants.GET_EVENTS_FAILURE:
    case dataConstants.GET_EVENT_DETAILS_FAILURE:
    case dataConstants.GET_ALBUMS_FAILURE:
    case dataConstants.GET_ALBUM_DETAILS_FAILURE:
    case dataConstants.GET_FACULTY_FAILURE:
    case dataConstants.GET_CONFERENCES_AND_WORKSHOPS_FAILURE:
    case dataConstants.GET_COURSES_FAILURE:
    case dataConstants.GET_COURSE_DETAILS_FAILURE:
    case dataConstants.GET_SYLLABUS_FAILURE:
    case dataConstants.GET_RESULTS_FAILURE:
    case dataConstants.GET_EXAM_SCHEDULE_FAILURE:      
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }    

    case dataConstants.RESET_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        message: null,        
      }

    case dataConstants.RESET:
      return initState;

    default: return state;
  }  
}