import { useSelector } from "react-redux";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillPhoneFill } from "react-icons/bs";
import { FaMapMarkedAlt } from "react-icons/fa";

import { Box, Flex, HeroSection, Text } from "atoms";
import { ContactForm, GetLink } from "molecules";

export const ContactUsPage = () => {

  const { footer } = useSelector(state => state.app);

  return (
    <Box>
      <HeroSection
        image="/images/home-contact.jpg"
        breadcrumb={[
          {
            label: "Home",
            url: "/"
          },
          {
            label: "Contact Us",
            url: "/contact-us"
          }
        ]}
      />
      <Box
        maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
        m="5rem auto"   
        py={{xm: "3rem"}}         
      >
        <Text
          fontSize={{xs: "1.8rem", md: "2.4rem"}}
          fontWeight="600"        
          mb="1.5rem"          
        >
          Let's get in touch          
        </Text>
        <Text
          fontSize={{xs: "1.5rem", md: "1.5rem"}}
          fontWeight="400"
          mb="1.5rem"
          color="grey.300"
        >
          We are here to answer any questions you may have about our services. Reach out to us and we'll respond as soon as we can.
        </Text>
      </Box>
      <Flex
        mt="4rem"
        flexDirection={{xs: "column", xm: "row"}}
      >
        {/* MAP SECTION */}
        <Box
          flex="1"
          bg="dark.100"        
        >             
          <Box 
            as="iframe"
            width="100%" 
            height="100%"
            minHeight="25rem"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13927.908126580662!2d79.4410297!3d29.2242301!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a09cf12bbbd563%3A0xae1b451f1e56d727!2sVasudev%20college%20of%20law!5e0!3m2!1sen!2sin!4v1697795453688!5m2!1sen!2sin"
            frameborder="0" 
            allowfullscreen
            style={{
              filter: "invert(90%)"
            }}
          />
        </Box>
        {/* CONTACT INFO SECTION */}
        <Flex
          flex="1"
          borderTop={{xm: "1px solid #e0e0e0"}}          
          flexDirection="column"          
          px={{xs: "3rem", md: "6rem", xm: "4rem"}}
          py={{xs: "5rem", md: "6rem", xm: "4rem"}}
          bg="grey.50" 
        >
          <Text
            fontSize={{xs: "1.8rem", md: "2.4rem"}}
            fontWeight="600"
            mb="3rem"            
          >
            Contact Info
          </Text>
          <Flex
            flexDirection="column"
          >
            <ContactCard
              Icon={<FaMapMarkedAlt/>}
              content={footer?.address}              
            />
            {footer?.phone?.map((item) => (
              <ContactCard
                key={item?.id}
                Icon={<BsFillPhoneFill/>}
                content={item?.value}
                url={`tel:${item?.value}`}
              />
            ))} 
            {footer?.email?.map((item) => (
              <ContactCard
                key={item?.id}
                Icon={<AiOutlineMail/>}
                content={item?.value}
                url={`mailTo:${item?.value}`}
              />
            ))}            
          </Flex>
        </Flex>
        {/* CONTACT FORM SECTION */}
        <Box
          flex="1"
        >
          <ContactForm/>
        </Box>
      </Flex>      
    </Box>
  )
}

const ContactCard = ({
  Icon,
  url="#",
  content
}) => (
  <GetLink    
    url={url}    
    display="flex"     
  >
    <Flex
      alignItems="center"                                 
      fontSize={{xs: "1.4rem", md: "1.5rem"}}
      mb="1.5rem"                
    >
      <Flex
        mr="1rem"
        alignItems="center"
        justifyContent="center"
        color="primary.500"
      >
        {Icon}
      </Flex>
      <Text
        color="grey.300"
        _hover={{
          color: "primary.500"
        }}
      >
        {content}
      </Text>
    </Flex>
  </GetLink>
)
