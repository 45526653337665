import { Box, Button, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";

export const HomeContactCard = () => {
  return (
    <Flex
      width="100%"    
      flexDirection={{xs: "column", md: "row"}}  
    >
      <Box
        width="100%"
        height={{ xs: "22rem", md: "44rem", xm: "50rem"}}
      >
        <Image
          src="/images/home-contact.jpg"
          alt="Contact"
          width="100%"
          height="100%"
          objectFit="cover"                  
        />
      </Box>
      <Box
        width={{ xs: "100%", md: "41rem", xm: "48rem"}}
        bg="rgba(0, 0, 0, 0.6)"
        height={{xs: "fit-content", md: "100%"}}
        position={{md: "absolute"}}
        top="0"
        left="0"
        py={{xs: "3rem", md: "1.5rem"}}
        px={{xs: "5rem", md: "9rem"}}
        color="white"
        fontWeight="600"
      >   
        <Text
          mt={{md: "9rem"}}
          fontSize={{xs: "2rem", md: "3.2rem"}}
          fontWeight="600"
          mb="1rem"
        >
          Contact Us
        </Text>
        <Text
          fontSize={{xs: "1.6rem", md: "2rem"}}
          fontWeight="400"
          mb="2rem"
          opacity="0.8"
        >
          Feel free to contact us for any query or suggestion. We will be happy to help you.
        </Text>     
        <GetLink url="/contact-us">
          <Button
            variant="secondary"
            mt="2rem"
            p="1rem 2rem"
            width="fit-content"
            borderRadius="0.5rem"
          >
            Click to Contact Us
          </Button>
        </GetLink>
      </Box>
    </Flex>
  )
}