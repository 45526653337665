import { useEffect, useState } from "react";

import { Box, Flex, Grid, HeroSection, Image, Text } from "atoms";
import { GetLink } from "molecules";
import SEO from "seo/SEO";
import { generatePublicURL } from "utils/utilities";
import { AboutMessage } from "./molecules";

export const AboutPage = ({
  aboutData,
  type,  
  loading
}) => {  
  
  const [ sections, setSections ] = useState([]);
  const [ currentSection, setCurrentSection ] = useState(null);
  const [ optionLinks, setOptionLinks ] = useState([]);
  const [ data, setData ] = useState(null);
  
  useEffect(() => {
    const _sections = [
      { id: "vision", label: "Our Vision", url: "/about/vision", seo: "Our Vision", type: "section" },
      { id: "mission", label: "Our Mission", url: "/about/mission", seo: "Our Mission", type: "section" },
      { id: "goals", label: "Our Goals", url: "/about/goals", seo: "Our Goals", type: "section" },
      { id: "about", label: "About College", url: "/about/about", seo: "About College", type: "section" },    
    ]    
    aboutData?.messages?.map(item => _sections?.push({
      id: item?.sectionUrl,
      label: item?.sectionId,
      url: `/about/${item?.sectionUrl}`,
      seo: item?.sectionId,
      type: "message"
    }));    

    setSections(_sections);
  }, [type, aboutData]);  

  useEffect(() => {    
    setCurrentSection(sections?.find(item => item?.id === type));
  }, [sections, type]);
  
  useEffect(() => {  
    if(currentSection?.id === type){
      if(currentSection?.type === "section") setData(aboutData?.[currentSection?.id] || []);
      if(currentSection?.type === "message") setData([aboutData?.messages?.find(item => item?.sectionUrl === type)]);      
    }    
  }, [currentSection, aboutData, type]);

  useEffect(() => {
    const assignOptionLinks = () => {      
      const _optionLinks = [];
      const _sections = [...sections];      
      _sections?.splice(_sections?.indexOf(currentSection), 1);
      for(let i = 0; i < 4; i++){
        const randomIndex = Math.floor(Math.random() * _sections?.length);
        _optionLinks?.push(_sections?.[randomIndex]);
        _sections?.splice(randomIndex, 1);
      }
      return _optionLinks;
    }    
    if(currentSection?.id === type) setOptionLinks(assignOptionLinks());
  }, [sections, type, currentSection])
    
  return (
    <Box>
      {(aboutData && currentSection?.id === type) ? <Box>
        <SEO
          title={`${currentSection?.seo || "About Us"} | Vasudev College Of Law, Haldwani`}          
          description={`${currentSection?.seo || "About Us"} | Vasudev College Of Law, Haldwani`}
          keywords={`${currentSection?.seo || "About Us"} | Vasudev College Of Law, Haldwani`}
        />

        <HeroSection
          image="/images/home-contact.jpg"
          breadcrumb={[
            {label: "Home", url: "/"},
            {label: "About", url: "/about"},
            {label: currentSection?.label, url: currentSection?.url}
          ]}
        /> 

        {data && <Box
          maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
          m="5rem auto"
        >
          {currentSection?.type === "message" ? <AboutMessage
            message={data[0]?.message?.split("<br/>")}
            name={data[0]?.name}
            designation={data[0]?.designation}
            title={data[0]?.title}
            image={data[0]?.image?.data ? generatePublicURL(data[0]?.image?.data?.attributes?.url) : null}
            sign={data[0]?.signature?.data ? generatePublicURL(data[0]?.signature?.data?.attributes?.url) : null}
          /> : currentSection?.type === "section" && <Box>
            {data && data?.map(item => {          
              switch(item?.__component) {            
                case "section.course-text":
                  return <PopulateTitleValue                   
                    key={item?.id} 
                    title={item?.title}
                    values={item?.description?.split("<br/>")}
                  />

                case "section.course-list":
                  return <PopulateTitleValue
                    title={item?.title}
                    values={item?.data?.map((li) => li?.value)}
                    type="list"
                  />

                case "section.course-images":
                  return <Images 
                    key={item?.id} 
                    title={item?.title}
                    images={item?.images?.data?.map(image => generatePublicURL(image?.attributes?.url))} 
                  />

                case "section.course-table":
                  return  <PopulateTable
                    values={item?.data?.map((row) => row)}
                  />                  
    
                default:
                  return null;
              }
            })}
          </Box>}
        </Box>}

        <Box
          maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
          m="5rem auto"
          mb="10rem"
        >
          <Text
            fontSize="2.4rem"
            fontWeight="600"
            className="heading-border"
            mb="3rem"
          >
            Also Visit:
          </Text>
          <Grid
            gridTemplateColumns={{xs: "1fr", md: "repeat(3, 1fr)", lg: "repeat(4, 1fr)"}}            
            gridGap="3rem"            
          >
            {optionLinks?.map((item, index) => (
              <Box
                key={index}
                width="96%"
                p="2.5rem"
                bg="primary.500"
                borderRadius="0.5rem"
                fontSize="1.8rem"
              >
                {item?.seo}
                <GetLink url={item?.url}>
                  <Box
                    width="98%"
                    p="2.5rem"
                    textAlign="center"
                    position="absolute"
                    top="1.5rem"
                    left="1.5rem"                    
                    bg="white"
                    zIndex="1"
                    border="1px solid"
                    borderColor="primary.500"
                    borderRadius="0.5rem"
                    fontSize="1.6rem"
                    fontWeight="500"
                    _hover={{ color: "secondary.500" }}
                    height="100%"
                  > 
                    {item?.seo}
                  </Box>
                </GetLink>
              </Box>
            ))}
          </Grid>
        </Box> 
      </Box> : !loading && <Box
        width="100%"
        height="40rem"
        pt="16rem"
        bg="dark.100"
        color="white"
        textAlign="center"
        fontSize="1.8rem"
      >
        Requested Resource Not Found
      </Box>}      
    </Box>
  )
}

const PopulateTitleValue = ({ title, values, type="paragraph" }) => (
  <Box
    mb={{xs: "2rem", md: "3rem"}}
  >
    {title && <Text
      fontSize={{ xs: "1.8rem", md: "2.6rem", lg: "2.8rem"}}
      fontWeight="600"      
      className="heading-border"
      mb="3rem"
    >
      {title}
    </Text>}    
    {values?.map((value, index) => (
      <>
        {type === "list" && <Flex
          alignItems="center" 
          mb="1.5rem"         
        >
          <Box
            width="1rem"
            height="1rem"
            bg="secondary.500"
            borderRadius="50%"
            mr="1rem"            
          />
          <Text      
            key={index}            
            fontSize="1.5rem"
            fontWeight="400"
            color="grey.300"
          >
            {value}
          </Text>
        </Flex>}
        {type === "paragraph" && <Text
          fontSize={{ xs: "1.5rem"}}
          color="grey.300"
          fontWeight="400"
          mb="1.4rem"              
          className="text-justify"                    
        >
          {value}
        </Text>}
      </>     
    ))}
  </Box>
)

const Images = ({images, title}) => (
  <Box my="3rem">
    {title && <Text
      fontSize={{ xs: "1.8rem", md: "2.4rem", lg: "2.6rem"}}
      fontWeight="600"      
      className="heading-border"
      mb="3rem"
      width="fit-content"
      mx={{xs: "unset", md: "auto"}}
    > 
      {title}
    </Text>}
    <Flex
      mt="1.5rem"
      flexWrap="wrap"         
      style={{gap: "2.5rem"}}    
      justifyContent="center"
    >
      {images?.map((image, index) => (
        <Box
          width={{xs: "90%", md: "45%", lg: "32%" }}
          key={index}
          height={{xs: "20rem", md: "30rem"}}                          
        >
          <Image
            src={image}
            alt={"image-" + String(index + 1)}
            width="100%"
            height="100%"
            objectFit="contain"
            borderRadius="0.5rem"
            preview={true}
          />
        </Box>
      ))}
    </Flex>
  </Box>
)

const PopulateTable = ({ values }) => (
  <Box
    style={{overflowX: "auto"}}    
    maxWidth={{
      xs: "calc(100vw - 6rem)",
      md: "unset"
    }}
  >    
    <Box
      as="table"      
      border="1px solid #707070"
      borderCollapse="collapse"
      mb="3rem"      
    >    
      {values?.map((item, index) => (
        <Box
          as="tr"
          key={index}
        >
          {item?.map((cell, i) => (
            <Box
              as="td"
              key={i}
              p="0.6rem"
              border="1px solid #707070"
            >
              <Text
                as="p"
                fontSize="1.4rem"
                fontWeight={cell?.type === "head" ? "700" :"500"}            
                color={cell?.type === "head" ? "primary.500" :"#707070"}            
              >
                {cell?.value}
              </Text>
            </Box>          
          ))}
        </Box>
      ))}
    </Box>
  </Box>
)