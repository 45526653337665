import { AiOutlineCloudDownload } from "react-icons/ai";

import { Flex, Text } from "atoms";
import { GetLink } from "molecules";
import { generatePublicURL } from "utils/utilities";

export const SyllabusCard = ({
  title,
  href
}) => {
  return (
    <GetLink
      url={generatePublicURL(href)}
      target="_blank"
      rel="noreferrer"
    >
      <Flex
        width="100%"
        bg="grey.50"
        borderRadius="0.4rem"
        p="1.5rem 2rem"
        alignItems="center"
        justifyContent="space-between"
        _hover={{
          borderBottom: "3px solid",
          borderColor: "secondary.500",
          color: "secondary.500"
        }}
      >
        <Text
          fontSize={{ xs: "1.2rem", md: "1.4rem" }}
          fontWeight="500"
          color="grey.500"
          transition="color 300ms ease-in-out"          
        >
          {"> "}{title}
        </Text>
        <Flex
          alignItems="center"
          justifyContent="center"
          fontSize={{ xs: "1.8rem", md: "2rem" }}                    
          color="grey.500"
        >
          <AiOutlineCloudDownload/>
        </Flex>
      </Flex>
    </GetLink>
  )
}