import React, { useEffect } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import Pagination from "react-js-pagination";

import { Box, Flex, HeroSection, InputDebounce, Text } from "atoms";
import { scrollToTop } from "utils/utilities";

export const PageSearchLayout = ({
  heroData,
  search,
  setSearch,
  resultPerPage,
  totalCount,
  page,
  setPage,
  notFound,
  children
}) => {

  useEffect(() => {
    setPage(1);
  }, [search, setPage]);

  useEffect(() => {
    scrollToTop(window);
  }, [page]);

  return (
    <Box>
      {heroData && <HeroSection
        image={heroData?.image}
        breadcrumb={heroData?.breadcrumb}
      />}
      <Box
        maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
        m="5rem auto"   
        py={{xm: "3rem"}}         
      >
        <Box 
          ml="auto" 
          mb="2rem"
          maxWidth={{xs: "100%", md: "32rem"}}
        >
          <InputDebounce            
            id="search"
            name="search"
            type="search"
            placeholder="Search"
            value={search}
            icon={<BiSearchAlt2/>}
            setValue={setSearch}            
            lessMB
            debounceTimeout={600}
          />
        </Box>
        {children}
        {resultPerPage < totalCount && <Flex
          justifyContent="center"
          my="4rem"
        >
          <Pagination
            activePage={page}
            itemsCountPerPage={resultPerPage}
            totalItemsCount={totalCount}
            onChange={(e) => setPage(e)}
            nextPageText="Next"
            prevPageText="Prev"
            firstPageText="1st"
            lastPageText="Last"
            itemClass="pagination-item"
            linkClass="page-link"
            activeClass="pagination-item-active"
            activeLinkClass="pagination-link-active"
          />
        </Flex>}       
        {totalCount > 0 &&  <Text
          textAlign="center"
          fontSize="1.4rem"
          fontWeight="600"
          color="secondary.200"
          my="3rem"
        >
          Total Entries Found : {totalCount}
        </Text>}
        {totalCount === 0 && <Box
          p="4rem"
        >
          <Text
            as="h4"
            fontSize="2.4rem"
            fontWeight="600"
            color="danger.500"
            textAlign="center"
            textTransform="capitalize"
          >
            {notFound}
          </Text>
        </Box>}
      </Box>      
    </Box>
  )
}