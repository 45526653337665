import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Text } from "atoms";
import { getAlbumDetails } from "redux/actions";
import SEO from "seo/SEO";
import { AlbumDetailsPage } from "templates";
import { deSlugAndCapitalize, scrollToTop } from "utils/utilities";

function AlbumDetails() {

  const { slug } = useParams();
  
  const dispatch = useDispatch();
  const { albumDetails } = useSelector(state => state.data);

  useEffect(() => {
    scrollToTop(window);
    if(slug !== albumDetails?.attributes?.slug) dispatch(getAlbumDetails(slug));
  }, [slug, dispatch, albumDetails])

  return (
    <>
      <SEO
        title={`${albumDetails?.attributes?.slug === slug ?  albumDetails?.attributes?.title : deSlugAndCapitalize(slug)} | Vasudev College Of Law, Haldwani`}
        description={albumDetails?.attributes?.title}
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/album/${slug}`}
      />      
      {albumDetails?.attributes?.slug !== slug ? 
        <Text bg="dark.100" color="white" py="3rem" textAlign="center">Album Not Found</Text>                
      : <AlbumDetailsPage
        album={albumDetails?.attributes}
      />}                   
    </>
  )
}

export default AlbumDetails;