import React from "react";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

import { Box, Button, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { BtnColors } from "utils/constant-data";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "4px",
    padding: "0px",
    border: "none",    
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "100"
  },
};

export const PopUpModal = ({ 
  isOpen, 
  onRequestClose, 
  ariaHideApp, 
  title,
  image,
  links
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={ariaHideApp}      
    >
      <Box
        width="100%"
        maxWidth={{xs: "30rem", md: "60rem"}}
        bg="white"
        p="2rem"
      >
        <Flex
          style={{gap: "2rem"}}
          alignItems="start"
          justifyContent="space-between"
        >
          {title && <Text
            fontWeight="600"
            fontSize={{ xs: "1.6rem", lg: "2rem" }}            
            color="black"            
            textAlign="start"            
          >
            {title}
          </Text>}  
          <Box
            onClick={onRequestClose}            
            cursor="pointer"            
            color="danger.100"
          >
            <AiOutlineClose size="2rem"/>
          </Box>                                  
        </Flex>
        {image && <Box
          width="100%"
          height={{xs: "24rem", md: "36rem"}}
          mt="1rem"
        >
          <Image
            src={image}
            alt={title}
            width="100%"
            height="100%"
            objectFit="contain"
            borderRadius="0.4rem"
          />
        </Box>}
        <Flex
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          mt="2rem"
          style={{gap: "1rem"}}
        >
          {links?.map((src, index) => (
            <GetLink
              key={src?.id}
              onClick={onRequestClose}
              url={src?.url}              
            >
              <Button
                variant={BtnColors[index%5]}
                minWidth={{xs: "10rem", md: "12rem"}}
                p="1.2rem"                                
                fontSize="1.2rem"
                borderRadius="0.4rem"
              >                
                {src?.title}               
              </Button>
            </GetLink>
          ))}          
        </Flex>
      </Box>
    </Modal>
  )
}