import { appConstants } from "redux/constants";
import Axios from "utils/axios";

export const getInitialData = () => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_INITIAL_DATA_REQUEST });      
      const { data } = await Axios.get(`/custom/get-initial-data`);                  
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_SUCCESS,
        payload: {
          header: data?.header,
          footer: data?.footer,
          home: {
            ...data?.homepage,
            news: data?.news,
            events: data?.events,            
            testimonials: data?.testimonials,            
            knowledgeResources: data?.knowledgeResources,            
          },
        }
      });            
    }catch(error){      
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_FAILURE, 
        payload: { error: error?.response?.data }
      });
    }
  }
}

export const sendContactMail = (form) => {
  return async dispatch => {
    try{
      dispatch({
        type: appConstants.SEND_CONTACT_US_REQUEST
      })
      const { data } = await Axios.post(`/custom/send-contact-email`, form)      
      dispatch({
        type: appConstants.SEND_CONTACT_US_SUCCESS,
        payload: {
          message: data?.message
        }
      })      
    }catch(error){      
      dispatch({
        type: appConstants.SEND_CONTACT_US_FAILURE,
        payload: {error: error?.response?.data}
      })
    }
  }
}