import { useEffect } from "react";

import SEO from "seo/SEO";
import { ExamSchedulePage } from "templates";
import { scrollToTop } from "utils/utilities";

const ExamSchedule = () => {
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Download Exam Schedules | Vasudev College Of Law, Haldwani"
        description="Download Exam Schedules of Vasudev College Of Law, Haldwani"
        keywords="Download Exam Schedules Vasudev College Of Law, Haldwani, Download Exam Schedules Vasudev College Of Law"        
      />   
      <ExamSchedulePage />
    </>
  )
}

export default ExamSchedule;