import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { toast } from "react-toastify";

import { Loader } from "atoms";
import Home from "pages/home";
import NotFound from "pages/not-found";
import About from "pages/about";
import FacultyStaff from "pages/faculty-staff";
import NewsEvents from "pages/news-events";
import ConferenceWorkshops from "pages/conference-workshops";
import Syllabus from "pages/syllabus";
import Results from "pages/result";
import ContactUs from "pages/contact-us";
import NewsDetails from "pages/news-details";
import EventDetails from "pages/event-details";
import ExamSchedule from "pages/exam-schedule";
import Courses from "pages/courses";
import Albums from "pages/albums";
import AlbumDetails from "pages/album-details";
import CourseDetails from "pages/course-details";
import { appConstants, dataConstants } from "redux/constants";

export const AppRoutes = () => {    

  const dispatch = useDispatch();
  const { loading, error, message } = useSelector((state) => state.app);
  const { loading: dataLoading, error: dataError, message: dataMessage } = useSelector((state) => state.data);

  useEffect(() => {
    if(message){
      toast.success(message);
      dispatch({ type: appConstants.RESET_APP });
    }
    if(error){
      toast.error(error);
      dispatch({ type: appConstants.RESET_APP });
    }    
    if(dataMessage){
      toast.success(dataMessage);
      dispatch({ type: dataConstants.RESET_DATA });
    }
    if(dataError){
      toast.error(dataError);
      dispatch({ type: dataConstants.RESET_DATA });
    }
  }, [message, error, dispatch, dataMessage, dataError]);

  return (
    <Suspense fallback={<Loader />}>
      {(loading || dataLoading) && <Loader />}
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about/:type" exact element={<About />} />
        <Route path="/news-events" exact element={<NewsEvents />} />
        <Route path="/news-events/:slug" exact element={<NewsDetails />} />        
        <Route path="/event/:slug" exact element={<EventDetails />} />
        <Route path="/faculty/faculty-staff-directory/:type" exact element={<FacultyStaff />} />
        <Route path="/conference-workshops" exact element={<ConferenceWorkshops />} />
        <Route path="/academics/syllabus" exact element={<Syllabus />} />
        <Route path="/academics/results" exact element={<Results />} />
        <Route path="/academics/courses" exact element={<Courses />} /> 
        <Route path="/academics/courses/:slug" exact element={<CourseDetails />} />        
        <Route path="/academics/exam-schedule" exact element={<ExamSchedule />} />
        <Route path="/contact-us" exact element={<ContactUs />} />
        <Route path="/albums" exact element={<Albums />} />
        <Route path="/album/:slug" exact element={<AlbumDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};