import { useEffect } from "react";

import SEO from "seo/SEO";
import { ConferenceWorkshopsPage } from "templates";
import { scrollToTop } from "utils/utilities";

const ConferenceWorkshops = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Conference & Workshops | Vasudev College Of Law, Haldwani"
        description="Conference & Workshops of Vasudev College Of Law, Haldwani"
        keywords="Conference & Workshops Vasudev College Of Law, Haldwani, Conference & Workshops Vasudev College Of Law"        
      />  
      <ConferenceWorkshopsPage/>       
    </>
  )
}

export default ConferenceWorkshops;