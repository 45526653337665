import { Box, Flex, Image, Text } from "atoms";

export const AboutMessage = ({
  message,
  name,
  designation,
  title,
  image,
  sign
}) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
    >
      {title && <Text
        fontSize="1.8rem"
        fontWeight="600"
        color="dark.100"
        mb="2rem"
      >
        {title}
      </Text>}
      {image && <Box
        width="30rem"
        height="30rem"        
        mb="4rem"
      >
        <Image
          src={image}
          alt={name}
          width="100%"
          height="100%"
          objectFit="cover" 
          borderRadius="0.4rem"         
        />
      </Box>}

      <Box mb="4rem">
        {message?.map((item, index) => (
          <Text 
            key={index}
            fontSize="1.5rem"
            color="grey.300"
            fontWeight="400"
            textAlign="center"
            mb="1.5rem"            
          >
            {item}
          </Text>
        ))}
        <Box          
          mt="3rem"
        >
          {sign && <Box
            width="10rem"
            height="5rem"
            mb="1rem"                      
          >
            <Image
              src={sign}
              alt={name}
              width="100%"
              height="100%"
              objectFit="cover"
            />
          </Box>}
          {name && <Text
            fontSize="1.6rem"
            color="grey.500"
            fontWeight="600"
            textAlign="right"
          >
            {name}
          </Text>}
          {designation && <Text
            fontSize="1.6rem"
            color="grey.500"
            fontWeight="600"
            textAlign="right"
          >
            {designation}
          </Text>}
        </Box>
      </Box>
    </Flex>
  )
}