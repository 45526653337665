import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { generatePublicURL } from "utils/utilities";

export const HomeKnowlegdeResources = ({ data }) => {
   
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,  
    autoplay: true,
    autoplaySpeed: 2500,  
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,          
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,          
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]    
  };

  return (
    <Box
      maxWidth={{xs: "90%", md: "70rem", xm: "100rem", lg: "120rem"}}
      mx="auto"
      mb="4rem"
    >
      <Text 
        pb="2rem"        
        mb="2rem"
        fontSize="2.4rem"
        className="heading-border" 
        fontWeight="600"       
      >
        Knowledge Resources
      </Text>
      <Box
        mt={{xs: "4rem"}}
      >
        <Slider {...settings}>
          {data?.map((item) => (
            <GetLink url={item?.url} key={item?.id}>
              <Flex              
                height={{xs: "5rem", md: "8rem"}}              
              >
                <Image
                  src={generatePublicURL(item?.image?.url)}
                  alt={item?.name}
                  width="100%"
                  height="100%"
                  objectFit="contain"
                  borderRadius="0.4rem"
                />
              </Flex>
            </GetLink>
          ))}          
        </Slider>
      </Box>
    </Box>
  )
}