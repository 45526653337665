import { createRef } from "react";
import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Image, Text } from "atoms";
import { generatePublicURL } from "utils/utilities";

export const HomeImageSlider = ({data}) => {

  const imageSlider = createRef();

  const gotoNext = () => {
    imageSlider.current.slickNext();
  };

  const gotoPrev = () => {
    imageSlider.current.slickPrev();
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,  
    lazyLoad: "progressive",  
    arrows: false,
  };  

  return (
    <Box
      width="100%"
      height={{xs: "32rem", md: "48rem", xm: "56rem", lg: "72rem"}}
    >      
      {/* SLIDER */}
      <Slider {...settings} ref={imageSlider}>
        {data?.map((item) => (
          <Box
            key={item?.id}
            width="100%"
            height={{xs: "32rem", md: "48rem", xm: "56rem", lg: "72rem"}}
          >
            <Image
              src={generatePublicURL(item?.image?.url)}
              alt={item.title}
              width="100%"
              height="100%"
              objectFit="cover"              
            />
            {item?.title && <Text                  
              maxWidth={{xs: "80%", lg: "60rem" }}
              position="absolute"
              left="50%"
              top="70%"
              transform="translate(-50%, -50%)"
              zIndex="10"              
              color="white"
              fontSize={{xs: "2rem", md: "2.6rem", xm: "3rem"}}
              fontWeight="600"
              textAlign="center"  
              cursor={item.href && "pointer"}                 
            >
              {item.title.slice(0,150)}
              {item.title.length > 150 && " ... "}                
            </Text>}            
          </Box>
        ))}
      </Slider>
      {/* LEFT ARROW */}
      <Box
        position="absolute"
        left="5%"
        top="50%"
        transform="translate(5%, -50%)"
        zIndex="1"
        color="white"
        cursor="pointer"
        _hover={{
          color: "white",
          transition: "all 300ms ease-in-out",           
          left: "4.8%",
          transform: "translate(4.8%, -50%)",
        }}
        fontSize="4rem"        
        onClick={gotoPrev}
      >
        <RiArrowLeftSFill/>
      </Box>
      {/* RIGHT ARROW */}
      <Box
        position="absolute"
        right="5%"
        top="50%"
        transform="translate(5%, -50%)"    
        color="white"
        cursor="pointer"
        zIndex="1"
        _hover={{
          color: "white",
          transition: "all 300ms ease-in-out",           
          right: "4.8%",
          transform: "translate(4.8%, -50%)",
        }}
        fontSize="4rem"        
        onClick={gotoNext}
      >
        <RiArrowRightSFill/>
      </Box>
    </Box>
  )
}