import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

import { InitialLoader } from "molecules";
import { getInitialData } from "redux/actions";
import { AppRoutes } from "routes";
import { theme } from "styles";
import "styles/globals.css";
import "styles/style.css";
import { Layout } from "organisms";

function App() {    

  const dispatch = useDispatch();
  const { initialLoading } = useSelector((state) => state.app);

  useEffect(() => {    
    dispatch(getInitialData());
  }, [dispatch]);

  return (    
    <ThemeProvider theme={theme}>
      <ToastContainer />
      {initialLoading ?
        <InitialLoader/>
      : <Router>
        <Layout>
          <AppRoutes />
        </Layout>
      </Router>}           
    </ThemeProvider>    
  );
}

export default App;