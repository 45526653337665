import React from "react";

import { Grid } from "atoms";
import { NavCard } from "molecules";
import { generatePublicURL } from "utils/utilities";

export const HomeFeatured = ({
  data
}) => {
  return (
    <Grid
      gridTemplateColumns={{xs: "1fr", md: "1fr 1fr 1fr"}}
      gridGap="1.5rem"
      width="100%" 
      maxWidth="120rem"
      m="5rem auto"            
      justifyItems="center"        
      px={{xs: "2rem", md: "4rem", lg: "0"}}
    >
      {data?.map((item) => (
        <NavCard
          key={item?.id}
          image={generatePublicURL(item?.image?.url)}
          title={item?.title}
          description={item?.description}
          linkText={item?.btnLabel}
          href={item?.btnUrl}            
        />
      ))}
    </Grid>
  )
}