import { Box, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { formatDate, generatePublicURL } from "utils/utilities";

export const NewsCard = ({
  image,
  title,
  timestamp,  
  tag,
  slug,
  type   
}) => {
  return (
    <GetLink
      url={`/news-events/${slug}`}
    >
      <Flex
        minWidth="32rem" 
        style={{gap: "1.5rem"}}               
      >
        <Box
          flex="0.8"
          minWidth="10rem"
          maxHeight="15rem"
          maxWidth="100%"
        >
          <Image
            src={generatePublicURL(image)}
            alt={title}
            width="100%"
            height="100%"
            objectFit="cover"
          />
        </Box>
        <Box flex="1">
          <Text
            fontSize="1.2rem"
            fontWeight="600"
            mb="1rem"
            textTransform="uppercase"
          >
            {type}{" "}<Text 
              as="span"
              fontSize="1.2rem"
              fontWeight="400"
              color="grey.300"
            >
              || {formatDate(timestamp)}
            </Text>
          </Text>
          {tag && <Text
            p="0.5rem 1rem"
            mb="1rem"
            bg="success.500"
            fontSize="1.2rem"
            borderRadius="2rem"
            width="fit-content"
            color="white"
            textTransform="uppercase"
          >  
            {tag}
          </Text>}
          <Text
            fontSize="1.6rem"
            fontWeight="500"
            _hover={{color: "primary.500"}}
          >
            {title.substring(0, 100)}
          </Text>
        </Box>
      </Flex>
    </GetLink>
  )
}