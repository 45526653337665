import { Box, Image, Text } from "atoms";
import { GetLink } from "molecules";

export const HeroSection = ({
  image,
  breadcrumb
}) => {  
  return (
    <Box
      width="100%"
      height={{xs: "22rem", md: "33rem", xm: "42rem", lg: "48rem"}}
    >
      <Image
        src={image}
        alt={breadcrumb[breadcrumb?.length-1]?.label}
        width="100%"
        height="100%"
        objectFit="cover"
      />
      {breadcrumb?.length > 0 && <Box
        position="absolute"
        bottom="0"
        left="0"
        pb="3rem"
        pl={{ xs: "2rem", md: "6rem", xm: "8rem", lg: "10rem" }}
        color="white"
      >
        <Text
          fontSize="1.4rem"
        >
          {breadcrumb?.slice(0, breadcrumb?.length-1)?.map((item, index) =>(
            <GetLink
              key={index}
              url={item?.url}
            >
              <Text
                as="span"
                fontSize="1.4rem"
                fontWeight="400"
              >
                {item?.label} {" > "}
              </Text>
            </GetLink>
          ))}
        </Text>
        <Text
          fontSize={{ xs: "2.2rem", md: "2.6rem", lg: "3.4rem"}}
          fontWeight="500"
        >
          {breadcrumb[breadcrumb?.length-1]?.label}
        </Text>
      </Box>}     
    </Box>
  )
}