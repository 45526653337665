import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { PageSearchLayout, SyllabusCard } from "molecules";
import { getSyllabus } from "redux/actions";

export const SyllabusPage = () => {

  const dispatch = useDispatch();
  const { syllabus } = useSelector(state => state.data);

  const [ page, setPage ] = useState(1);
  const [ search, setSearch ] = useState("");  

  useEffect(() => {    
    dispatch(getSyllabus(
      page,
      12,
      search
    ));    
  }, [search, page, dispatch]); 

  return (
    <>
      <PageSearchLayout
        heroData={{
          image: "/images/home-contact.jpg",
          breadcrumb: [
            {label: "Home", url: "/"},
            {label: "Syllabus", url: "/academics/syllabus"}
          ]
        }}
        search={search}
        setSearch={setSearch}
        totalCount={syllabus?.meta?.pagination?.total}
        resultPerPage={syllabus?.meta?.pagination?.pageSize}
        page={page}
        setPage={setPage}
        notFound="No syllabus found"
      >    
        <Grid
          gridTemplateColumns={{xs: "1fr", xm: "repeat(2, 1fr)"}}
          gridGap="2.5rem"
        >
          {syllabus?.data?.map((file) => (
            <SyllabusCard
              key={file?.id}
              title={file?.attributes?.title}
              href={file?.attributes?.file?.data?.attributes?.url}
            />
          ))}          
        </Grid>       
      </PageSearchLayout>
    </>
  )
}