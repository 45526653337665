import { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { PageSearchLayout, ResultCard } from "molecules";
import { getResults } from "redux/actions";

export const ResultsPage = () => { 
  
  const dispatch = useDispatch();
  const { result } = useSelector(state => state.data);

  const [ page, setPage ] = useState(1);
  const [ search, setSearch ] = useState("");

  useEffect(() => {    
    dispatch(getResults(
      page,
      12,
      search    
    ));
  }, [page, search, dispatch]);   
  
  return (
    <>
      <PageSearchLayout
        heroData={{
          image: "/images/home-contact.jpg",
          breadcrumb: [
            {label: "Home", url: "/"},
            {label: "Results", url: "/academics/results"}
          ]
        }}
        search={search}
        setSearch={setSearch}
        totalCount={result?.meta?.pagination?.total}
        resultPerPage={result?.meta?.pagination?.pageSize}
        page={page}
        setPage={setPage}
        notFound="No results found"
      >    
        <Grid
          gridTemplateColumns={{xs: "1fr", xm: "repeat(2, 1fr)"}}
          gridGap="2.5rem"
        >
          {result?.data?.map((result) => (
            <ResultCard
              key={result?.id}
              title={result?.attributes?.title}
              href={result?.attributes?.url}
              timestamp={moment(result?.attributes?.createdAt).fromNow()}
            />
          ))}          
        </Grid>                
      </PageSearchLayout>
    </>
  )
}