import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Text } from "atoms";
import { getNewsDetails } from "redux/actions";
import SEO from "seo/SEO";
import { NewsDetailsPage } from "templates";
import { deSlugAndCapitalize, scrollToTop } from "utils/utilities";

const NewsDetails = () => {  

  const { slug } = useParams();
  
  const dispatch = useDispatch();
  const { newsDetails } = useSelector(state => state.data);

  useEffect(() => {
    scrollToTop(window);
    if(slug !== newsDetails?.attributes?.slug) dispatch(getNewsDetails(slug));
  }, [slug, dispatch, newsDetails])

  return (
    <>
      <SEO
        title={`${newsDetails?.attributes?.slug === slug ?  newsDetails?.attributes?.title : deSlugAndCapitalize(slug)} | Vasudev College Of Law, Haldwani`}
        description={newsDetails?.attributes?.title}
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/news-events/${slug}`}
      />      
      {newsDetails?.attributes?.slug !== slug ? 
        <Text bg="dark.100" color="white" py="3rem" textAlign="center">News Not Found</Text>                
      : <NewsDetailsPage
        news={newsDetails?.attributes}
      />}   
    </>
  )
}

export default NewsDetails;