import React, { useEffect } from "react";

import { ErrorPage } from "templates";
import { scrollToTop } from "utils/utilities";

export default function NotFound() {

  document.title = "404 | Page Not Found | Vasudev College Of Law, Haldwani"

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <ErrorPage/>
    </>
  );
}