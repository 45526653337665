import { useEffect } from "react";

import SEO from "seo/SEO";
import { ResultsPage } from "templates";
import { scrollToTop } from "utils/utilities";

const Results = () => {
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Download Results | Vasudev College Of Law, Haldwani"
        description="Download Results of Vasudev College Of Law, Haldwani"
        keywords="Download Results Vasudev College Of Law, Haldwani, SDownload Results Vasudev College Of Law"        
      />   
      <ResultsPage/>
    </>
  )
}

export default Results;