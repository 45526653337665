import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { PageSearchLayout, SyllabusCard } from "molecules";
import { getExamSchedule } from "redux/actions";
import { generatePublicURL } from "utils/utilities";

export const ExamSchedulePage = () => {

  const dispatch = useDispatch();
  const { examSchedule } = useSelector(state => state.data);

  const [ page, setPage ] = useState(1);
  const [ search, setSearch ] = useState("");

  useEffect(() => {    
    dispatch(getExamSchedule(
      page,
      12,
      search
    ));    
  }, [search, page, dispatch]);  

  return (
    <>
      <PageSearchLayout
        heroData={{
          image: "/images/home-contact.jpg",
          breadcrumb: [
            {label: "Home", url: "/"},
            {label: "Exam Schedules", url: "/academics/exam-schedule"}
          ]
        }}
        search={search}
        setSearch={setSearch}
        totalCount={examSchedule?.meta?.pagination?.total}
        resultPerPage={examSchedule?.meta?.pagination?.pageSize}
        page={page}
        setPage={setPage}
        notFound="No exam schedules found"
      >      
        <Grid
          gridTemplateColumns={{xs: "1fr", xm: "repeat(2, 1fr)"}}
          gridGap="2.5rem"
        >
          {examSchedule?.data?.map((file) => (
            <SyllabusCard
              key={file?.id}
              title={file?.attributes?.title}
              href={generatePublicURL(file?.attributes?.file?.data?.attributes?.url)}
            />
          ))}          
        </Grid>
      </PageSearchLayout>      
    </>
  )
}