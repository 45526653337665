import { Box, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";

export const NavCard = ({
  image,
  title,
  description,
  linkText,
  href,  
}) => {
  return (
    <Flex
      width={{xs: "32rem", md: "18rem", xm: "28rem", lg: "34rem"}}            
      flexDirection="column"
    >
      <Box
        width={{xs: "32rem", md: "18rem", xm: "28rem", lg: "34rem"}}
        height={{xs: "18rem", md: "12rem", xm: "18rem", lg: "20rem"}}
        mb="2rem"
      >
        <Image
          src={image}
          alt={title}
          width="100%"
          height="100%"
          objectFit="cover"
        />
      </Box>
      <Flex 
        flexGrow="1"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Text
            as="h2"
            mb="1.6rem"
            fontSize="1.8rem"          
            fontWeight="600"
          >
            {title}
          </Text>
          <Text
            as="p"
            mb="1.6rem"
            fontSize="1.4rem"
            color="grey.300"
            fontWeight="400"            
          >
            {description}
          </Text>
        </Box>
        {href && <GetLink
          url={href}
        >
          <Box
            textTransform="uppercase"
            fontSize="1.4rem"
            fontWeight="600"
            color="grey.500"
            _hover={{color: "secondary.500"}}
            mb="2rem"          
          >           
            {linkText}           
          </Box>
        </GetLink>}
      </Flex>
    </Flex>
  )
}