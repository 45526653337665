import { FaExternalLinkAlt } from "react-icons/fa";

import { Flex, Text } from "atoms";
import { GetLink } from "molecules";
import { generatePublicURL } from "utils/utilities";

export const ResultCard = ({
  title,
  href,
  timestamp
}) => {
  return (
    <GetLink
      url={generatePublicURL(href)}
      target="_blank"
      rel="noreferrer"
    >
      <Flex
        width="100%"
        bg="grey.50"
        borderRadius="0.4rem"
        p="1.5rem 2rem"
        alignItems="center"
        justifyContent="space-between"
        _hover={{
          borderBottom: "3px solid",
          borderColor: "secondary.500",
          color: "secondary.500"
        }}
      >
        <Text
          fontSize={{ xs: "1.2rem", md: "1.4rem" }}
          fontWeight="500"
          color="grey.500"
          transition="color 300ms ease-in-out"          
        >
          {"> "}{title}
          {timestamp && <Text
            as="span"
            fontSize={{ xs: "1.2rem", md: "1.4rem" }}
            fontWeight="400"
            color="grey.300"            
            ml="0.5rem"
          >
            - {timestamp}
          </Text>}
        </Text>
        <Flex
          alignItems="center"
          justifyContent="center"
          fontSize={{ xs: "1.8rem", md: "2rem" }}                    
          color="grey.500"
        >
          <FaExternalLinkAlt/>
        </Flex>
      </Flex>
    </GetLink>
  )
}